<template>
  <div>
    <template v-if="created">
      <span>Person created</span>
    </template>
    <template v-else-if="deleted">
      <span>Person deleted</span>
    </template>
    <template v-else-if="changes.length > 0">
      <ul v-if="expand">
        <li v-for="(c, i) in changes" :key="i">{{ c }}</li>
      </ul>
      <span v-else>{{ changes.join('; ') }}</span>
    </template>
    <template v-else>
      <span>Unknown change</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PersonChange',
  props: {
    expand: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      required: true
    }
  },
  computed: {
    after() {
      return this.changeDetail.after || {};
    },
    before() {
      return this.changeDetail.before || {};
    },
    changeDetail() {
      if (!this.record) {
        return {};
      }
      return this.record.change;
    },
    paths() {
      if (!this.record) {
        return {};
      }
      return this.record.paths;
    },

    created() {
      return this.changeDetail.hasOwnProperty('after') && !this.changeDetail.hasOwnProperty('before');
    },
    deleted() {
      return this.changeDetail.hasOwnProperty('before') && !this.changeDetail.hasOwnProperty('after');
    },

    multipleChanges() {
      return !this.created && !this.deleted && this.changes.length > 1;
    },

    changes() {
      const changes = [];
      const stringFields = {
        title: 'Full Name',
        displayName: 'Display Name',
        email: 'Email',
        department: 'Department',
        position: 'Position',
        costCentre: 'Cost Centre'
      };
      for (const [field, name] of Object.entries(stringFields)) {
        if (this.paths.includes(field)) {
          const removed = !this.after.hasOwnProperty(field);
          let change = name;
          if (removed) {
            change += ` removed`;
          } else {
            change += ` updated to ${this.after[field]}`;
          }
          changes.push(change);
        }
      }
      if (this.paths.includes('active')) {
        const isActive = this.after.active;
        changes.push(`Set ${isActive ? 'active' : 'inactive'}`);
      }
      if (this.paths.some(p => p.startsWith('phones'))) {
        changes.push(`Phone number(s) updated`);
      }
      if (this.paths.some(p => p.startsWith('employer'))) {
        changes.push(`Employer updated to ${this.after.employer.title}`);
      }
      if (this.paths.includes('profilePicture')) {
        changes.push(`Profile picture updated`);
      }
      if (this.paths.some(p => p.startsWith('tags'))) {
        changes.push(`Tags updated`);
      }
      return changes;
    }
  },
  watch: {
    multipleChanges: {
      handler(mc) {
        this.$emit('multiple-changes', mc);
      },
      immediate: true
    }
  }
};
</script>

<style scoped>

</style>
