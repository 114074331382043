<template>
  <v-container fluid class="pa-0 audit-logs">
    <v-toolbar height="56">
      <day-chooser v-model="day" :max="todayYearMonthDay"/>
      <v-spacer/>
      <v-btn @click="refresh" outlined>refresh<v-icon right>mdi-refresh</v-icon></v-btn>
    </v-toolbar>
    <audit-logs-list :items="changes"/>
    <div v-observe-visibility="observerOptions"/>
    <v-card :loading="loadingInfo.loading" flat tile>
      <v-alert v-if="loadingInfo.text" :type="loadingInfo.type" tile text class="ma-0">
        {{ loadingInfo.text }}
      </v-alert>
    </v-card>
  </v-container>
</template>

<script>
import AuditLogsList from '@/views/admin/audit/AuditLogsList';
import {mapActions, mapGetters} from 'vuex';
import {ObserveVisibility} from 'vue-observe-visibility';
import DayChooser from '@/views/room-booking/calendar/DayChooser';
import {currentDate, endOfDay, startOfDay, toYearMonthDay} from '@/util/dates';

export default {
  name: 'AuditLogs',
  components: {DayChooser, AuditLogsList},
  directives: {ObserveVisibility},
  data() {
    return {
      today: startOfDay(),
      day: currentDate(),
      // we should attempt to fetch more records, assuming we think that will be successful
      itemsNeedLoading: true,
      observerOptions: {
        callback: this.handleIntersect,
        intersection: {
          rootMargin: '100px'
        }
      }
    };
  },
  computed: {
    ...mapGetters('views/admin/audit', ['changes', 'loadingInfo']),
    ...mapGetters('auth', ['hasAuthUser']),
    todayYearMonthDay() {
      return toYearMonthDay(this.today);
    },
    startOfDay() {
      return startOfDay(this.day);
    },
    endOfDay() {
      return endOfDay(this.day);
    },
    queryClauses() {
      const clauses = [];
      clauses.push(['change.changeTime', '>=', this.startOfDay]);
      clauses.push(['change.changeTime', '<=', this.endOfDay]);
      return clauses;
    }
  },
  watch: {
    queryClauses: {
      deep: true,
      handler(clauses) {
        this.getChanges(clauses);
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('views/admin/audit', ['bind', 'load', 'stop', 'unbind']),
    getChanges(clauses) {
      if (!this.hasAuthUser) return;
      this.$logger.debug('getChanges', clauses);
      this.bind(clauses)
          .then(() => {
            if (this.itemsNeedLoading) {
              return this.load();
            }
          })
          .catch(err => this.$logger.warn('Failed bind after query change', err));
    },
    async refresh() {
      try {
        await this.unbind();
        await this.bind(this.queryClauses);
        if (this.itemsNeedLoading) {
          return this.load();
        }
      } catch (e) {
        this.$logger.warn('Failed refresh', e);
      }
    },
    /**
     * @param {boolean} isVisible
     */
    handleIntersect(isVisible) {
      this.itemsNeedLoading = isVisible;
      if (this.itemsNeedLoading) {
        this.load();
      } else {
        this.stop();
      }
    }
  }
};
</script>

<style scoped>
.audit-logs > .v-toolbar {
  position: sticky;
  top: 0;
  left: 0;
  /* 6 is the same as the nav drawer, this means we're above the box-shadow */
  /*z-index: 6;*/
}

</style>
