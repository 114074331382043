<template>
  <div class="audit-logs-list-row" @click="rowClick">
    <div class="td" :style="styleFor('date')" v-if="containsHeader('date')">
      {{ tsDisplay(record.change.changeTime) }}
    </div>
    <div class="td" :style="styleFor('user')" v-if="containsHeader('user')">
      <router-link v-if="triggeredBy" :to="{name: 'user-view', params: {id: triggeredByRefId}}">
        {{ triggeredBy && triggeredBy.title }}
      </router-link>
      <span v-else-if="triggeredVia">
        {{ triggeredVia && triggeredVia.title }}
      </span>
      <span v-else>-</span>
    </div>
    <div class="td" :style="styleFor('type')" v-if="containsHeader('type')">
      {{ kind }}
    </div>
    <div class="td" :style="styleFor('subject')" v-if="containsHeader('subject')">
      <router-link :to="docLink">{{ docTitle }}</router-link>
    </div>
    <div class="td" :style="styleFor('description')" v-if="containsHeader('description')">
      <v-menu v-model="expand">
        <template #activator="{}">
          <change-detail :collection-id="collectionId" :record="record" @multiple-changes="canExpand = $event"/>
        </template>
        <v-card>
          <v-card-title>Changes to: {{ docTitle }}</v-card-title>
          <v-card-text>
            <change-detail :collection-id="collectionId" :record="record" :expand="expand"/>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
    <div class="td" :style="styleFor('menu')" v-if="containsHeader('menu')"/>
  </div>
</template>

<script>
import moment from 'moment';
import ChangeDetail from '@/views/admin/audit/ChangeDetail';

export default {
  name: 'AuditLogsListRow',
  components: {ChangeDetail},
  props: {
    record: {
      type: Object,
      default() {
        return {};
      }
    },
    headers: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      canExpand: false,
      expand: false
    };
  },
  computed: {
    /** @return {kahu.firestore.Change|undefined} */
    change() {
      return this.record && this.record.change;
    },
    /** @return {kahu.firestore.DocumentSnippet} */
    doc() {
      return this.change && this.change.document;
    },
    /** @return {firebase.firestore.DocumentReference} */
    docRef() {
      return this.doc && this.doc.ref;
    },
    docId() {
      return this.docRef && this.docRef.id;
    },
    docTitle() {
      if (!this.doc) {
        return '';
      }
      if (this.doc.title) {
        return this.doc.title;
      }
      return this.docId;
    },
    docLink() {
      let name;
      switch (this.collectionId) {
        case 'cards':
          name = 'card';
          break;
        case 'members':
          name = 'person';
          break;
        case 'people':
          name = 'person';
          break;
        case 'users':
          name = 'user-view';
          break;
      }
      return {
        name,
        params: {id: this.docId}
      };
    },
    collectionId() {
      return this.docRef && this.docRef.parent.id;
    },
    kind() {
      switch (this.collectionId) {
        case 'cards':
          return 'Card';
        case 'members':
          return 'Site Membership';
        case 'people':
          return 'Person';
        case 'users':
          return 'User';
      }
      return this.collectionId;
    },
    /** @return {kahu.firestore.UserSnippet|undefined} */
    triggeredBy() {
      return this.change && this.change.triggeredBy;
    },
    triggeredByRef() {
      return this.triggeredBy && this.triggeredBy.ref;
    },
    triggeredByRefId() {
      return this.triggeredByRef && this.triggeredByRef.id;
    },
    /** @return {kahu.firestore.DocumentSnippet|undefined} */
    triggeredVia() {
      return this.change && this.change.triggeredVia;
    }
  },
  methods: {
    rowClick() {
      if (!this.canExpand) return;
      this.expand = !this.expand;
    },
    tsDisplay(ts) {
      return moment(ts.toDate()).format('YYYY-MM-DD HH:mm:ss');
    },
    containsHeader(value) {
      return !!this.headers.find(h => h.value === value);
    },
    styleFor(value) {
      const h = this.headers.find(h => h.value === value);
      if (!h) {
        return {};
      }
      return {
        width: h.width
      };
    }
  }
};
</script>

<style scoped>
.audit-logs-list-row {
  display: flex;
}
.audit-logs-list-row .td:last-child {
  padding: 0;
}
.audit-logs-list-row.expand .td {
  height: unset;
  padding-top: 16px;
  padding-bottom: 16px;
}

.td {
  font-size: 0.875rem;
  height: 52px;
  padding: 0 16px;
}

.audit-logs-list-row .td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
