<template>
  <div>
    {{ siteName }}:
    <template v-if="created">
      <span>Membership created</span>
    </template>
    <template v-else-if="deleted">
      <span>Membership deleted</span>
    </template>
    <template v-else-if="changes.length > 0">
      <ul v-if="expand">
        <li v-for="(c, i) in changes" :key="i">{{ c }}</li>
      </ul>
      <span v-else>{{ changes.join('; ') }}</span>
    </template>
    <template v-else>
      <span>Unknown change</span>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import {mapGetters} from 'vuex';

export default {
  name: 'MemberChange',
  props: {
    expand: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('sites', ['sitesById']),
    after() {
      return this.changeDetail.after || {};
    },
    before() {
      return this.changeDetail.before || {};
    },
    changeDetail() {
      if (!this.record) {
        return {};
      }
      return this.record.change;
    },
    paths() {
      if (!this.record) {
        return {};
      }
      return this.record.paths;
    },
    siteId() {
      return this.changeDetail.document.ref.parent.parent.id;
    },
    siteDoc() {
      if (!this.siteId) {
        return null;
      }
      // ns.admins have access to all sites, so this should always work
      return this.sitesById[this.siteId];
    },
    siteName() {
      if (!this.siteDoc) {
        return null;
      }
      return this.siteDoc.title;
    },

    created() {
      return this.changeDetail.hasOwnProperty('after') && !this.changeDetail.hasOwnProperty('before');
    },
    deleted() {
      return this.changeDetail.hasOwnProperty('before') && !this.changeDetail.hasOwnProperty('after');
    },

    multipleChanges() {
      return !this.created && !this.deleted && this.changes.length > 1;
    },

    changes() {
      const changes = [];

      if (this.paths.includes('validFrom')) {
        const exists = this.after.hasOwnProperty('validFrom');
        let change = 'Valid From';
        if (exists) {
          change += ` updated to ${this.tsDisplay(this.after.validFrom)}`;
        } else {
          change += ` removed`;
        }
        changes.push(change);
      }
      if (this.paths.includes('validTo')) {
        const exists = this.after.hasOwnProperty('validTo');
        let change = 'Valid To';
        if (exists) {
          change += ` updated to ${this.tsDisplay(this.after.validTo)}`;
        } else {
          change += ` removed`;
        }
        changes.push(change);
      }
      if (this.paths.some(p => p.startsWith('neighbourhood'))) {
        changes.push(`Neighbourhood set to ${this.after.neighbourhood.title}`);
      }
      if (this.paths.some(p => p.startsWith('defaultFloor'))) {
        changes.push(`Default floor set to ${this.after.defaultFloor.title}`);
      }
      if (this.addedPermissions.length > 0) {
        const plural = this.addedPermissions.length > 1 ? 's' : '';
        let change = `Added Permission${plural} `;
        change += this.addedPermissions.map(v => this.permissionNames[v]).join(', ');
        changes.push(change);
      }
      if (this.removedPermissions.length > 0) {
        const plural = this.removedPermissions.length > 1 ? 's' : '';
        let change = `Removed Permission${plural} `;
        change += this.removedPermissions.map(v => this.permissionNames[v]).join(', ');
        changes.push(change);
      }

      // access control changes
      if (this.paths.includes('accessControl.profile')) {
        const exists = this.after.accessControl && this.after.accessControl.hasOwnProperty('profile');
        let change = 'Access Control Profile ';
        if (exists) {
          const profile = this.after.accessControl.profile;
          change += ` updated to ${this.accessProfileNames[profile]}`;
        } else {
          change += ` removed`;
        }
        changes.push(change);
      }
      if (this.paths.includes('accessControl.pin')) {
        const exists = this.after.accessControl && this.after.accessControl.hasOwnProperty('pin');
        changes.push(`Access Control PIN ${exists ? 'updated' : 'removed'}`);
      }
      if (this.addedAccessPermissions.length > 0) {
        const plural = this.addedAccessPermissions.length > 1 ? 's' : '';
        let change = `Added Access Permission${plural} `;
        change += this.addedAccessPermissions.map(v => this.accessPermissionNames[v]).join(', ');
        changes.push(change);
      }
      if (this.removedAccessPermissions.length > 0) {
        const plural = this.removedAccessPermissions.length > 1 ? 's' : '';
        let change = `Removed Access Permission${plural} `;
        change += this.removedAccessPermissions.map(v => this.accessPermissionNames[v]).join(', ');
        changes.push(change);
      }

      return changes;
    },

    addedPermissions() {
      const added = [];
      for (const [id, enabled] of Object.entries(this.after.permissions || {})) {
        if (enabled) {
          added.push(id);
        }
      }
      return added;
    },
    removedPermissions() {
      const added = [];
      for (const [id, enabled] of Object.entries(this.before.permissions || {})) {
        if (enabled) {
          added.push(id);
        }
      }
      return added;
    },
    permissionNames() {
      const perms = this.$store.state.appConfig.permissions;
      const addPerm = (byId, perm, prefix) => {
        byId[perm.v] = `"${prefix}${perm.t}"`;
        if (perm.children) {
          for (const p of (perm.children)) {
            addPerm(byId, p, `${prefix}${perm.t} `);
          }
        }
      };
      return perms.reduce((byId, perm) => {
        addPerm(byId, perm, '');
        return byId;
      }, {});
    },

    addedAccessPermissions() {
      if (!this.after.hasOwnProperty('accessControl') ||
          !this.after.accessControl.hasOwnProperty('permissions')) {
        return [];
      }
      const added = [];
      for (const [id, enabled] of Object.entries(this.after.accessControl.permissions || {})) {
        if (enabled) {
          added.push(id);
        }
      }
      return added;
    },
    removedAccessPermissions() {
      if (!this.before.hasOwnProperty('accessControl') ||
          !this.before.accessControl.hasOwnProperty('permissions')) {
        return [];
      }
      const removed = [];
      for (const [id, enabled] of Object.entries(this.before.accessControl.permissions || {})) {
        if (enabled) {
          removed.push(id);
        }
      }
      return removed;
    },
    accessProfileNames() {
      if (!this.siteDoc) {
        return {};
      }
      const byId = {};
      const acOptions = this.siteDoc.accessControlOptions || {};
      const acProfiles = acOptions.profiles || {};
      for (const perm of acProfiles) {
        byId[perm.value] = `"${perm.title}"`;
      }
      return byId;
    },
    accessPermissionNames() {
      if (!this.siteDoc) {
        return {};
      }
      const byId = {};
      const acOptions = this.siteDoc.accessControlOptions || {};
      const acPerms = acOptions.permissions || {};
      for (const perm of acPerms) {
        byId[perm.value] = `"${perm.title}"`;
      }
      return byId;
    }
  },
  watch: {
    multipleChanges: {
      handler(mc) {
        this.$emit('multiple-changes', mc);
      },
      immediate: true
    }
  },
  methods: {
    tsDisplay(ts) {
      return moment(ts.toDate()).format('YYYY-MM-DD HH:mm:ss');
    }
  }
};
</script>

<style scoped>
div {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
