<template>
  <div>
    <template v-if="created">
      <span>User created</span>
    </template>
    <template v-else-if="deleted">
      <span>User deleted</span>
    </template>
    <template v-else-if="defaultSiteChange">
      <span>Default site updated</span>
    </template>
    <template v-else-if="personLinkChange">
      <span>
        {{ personUnLinked ? 'Unlinked from' : 'Linked to' }}
        <router-link :to="{name: 'person', params: {id: personsId}}">
          {{ personsName }}
        </router-link>
      </span>
    </template>
    <template v-else-if="rolesChange">
      <span v-if="rolesAddedNames.length > 0">Roles added: {{ rolesAddedNames.join(',') }}</span>
      <span v-if="rolesAddedNames.length > 0 && rolesRemovedNames.length > 0">, </span>
      <span v-if="rolesRemovedNames.length > 0">Roles removed: {{ rolesRemovedNames.join(',') }}</span>
    </template>
    <template v-else-if="sitesChange">
      <span v-if="sitesAdded.length > 0">Sites added: {{ sitesAddedNames.join(',') }}</span>
      <span v-if="sitesAdded.length > 0 && sitesRemoved.length > 0">, </span>
      <span v-if="sitesRemoved.length > 0">Sites removed: {{ sitesRemovedNames.join(',') }}</span>
    </template>
    <template v-else>
      <span>Unknown change</span>
    </template>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

export default {
  name: 'UserChange',
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('sites', ['sitesById']),
    after() {
      return this.changeDetail.after || {};
    },
    before() {
      return this.changeDetail.before || {};
    },
    changeDetail() {
      if (!this.record) {
        return {};
      }
      return this.record.change;
    },
    paths() {
      if (!this.record) {
        return {};
      }
      return this.record.paths;
    },

    created() {
      return this.after && !this.before;
    },
    deleted() {
      return this.before && !this.after;
    },

    defaultSiteChange() {
      return this.paths.includes('defaultSite');
    },
    personLinkChange() {
      return this.paths.includes('person.ref');
    },
    personUnLinked() {
      return !this.after.person || !this.after.person.ref;
    },
    personsId() {
      if (!this.personLinkChange) {
        return '';
      }
      const person = this.after.person || this.before.person;
      return person.ref.id;
    },
    personsName() {
      if (!this.personLinkChange) {
        return '';
      }
      const person = this.after.person || this.before.person;
      if (person.displayName) {
        return person.displayName;
      }
      if (person.title) {
        return person.title;
      }
      return person.ref.id;
    },
    rolesChange() {
      return this.paths.some(p => p.startsWith('roles.'));
    },
    rolesAfter() {
      if (!this.rolesChange) {
        return [];
      }
      return Object.values(this.after.roles || {});
    },
    rolesBefore() {
      if (!this.rolesChange) {
        return [];
      }
      return Object.values(this.before.roles || {});
    },
    rolesAdded() {
      // since these are arrays, the order can change, so the change can include
      // a role removed and added (but really just in a different array position)
      return this.rolesAfter.filter(r => !this.rolesBefore.includes(r));
    },
    rolesAddedNames() {
      return this.rolesAdded.map(r => this.roleNames[r]).filter(Boolean);
    },
    rolesRemoved() {
      // since these are arrays, the order can change, so the change can include
      // a role removed and added (but really just in a different array position)
      return this.rolesBefore.filter(r => !this.rolesAfter.includes(r));
    },
    rolesRemovedNames() {
      return this.rolesRemoved.map(r => this.roleNames[r]).filter(Boolean);
    },
    roleNames() {
      const roles = this.$store.state.appConfig.roles;
      return roles.reduce((byId, group) => {
        for (const r of group.roles) {
          byId[r.key] = group.title === 'General' ? r.title : `${group.title} ${r.title}`;
        }
        return byId;
      }, {});
    },
    sitesChange() {
      return this.paths.some(p => p.startsWith('sites.'));
    },
    sitesAdded() {
      if (!this.sitesChange) {
        return [];
      }
      return Object.values(this.after.sites || {});
    },
    sitesAddedNames() {
      return this.sitesAdded.map(s => this.sitesById[s].title);
    },
    sitesRemoved() {
      if (!this.sitesChange) {
        return [];
      }
      return Object.values(this.before.sites || {});
    },
    sitesRemovedNames() {
      return this.sitesRemoved.map(s => this.sitesById[s].title);
    }
  }
};
</script>

<style scoped>
  .strikeout {
    text-decoration: line-through;
  }
</style>
