<template>
  <card-history-change :change="record" v-if="collectionId === 'cards'"/>
  <member-change
      v-else-if="collectionId === 'members'"
      @multiple-changes="$emit('multiple-changes', $event)"
      :expand="expand"
      :record="record"/>
  <person-change
      v-else-if="collectionId === 'people'"
      @multiple-changes="$emit('multiple-changes', $event)"
      :record="record"
      :expand="expand"/>
  <user-change :record="record" v-else-if="collectionId === 'users'"/>
  <span v-else>-</span>
</template>

<script>
import CardHistoryChange from '@/views/people/card/components/CardHistoryChange';
import MemberChange from '@/views/admin/audit/MemberChange';
import PersonChange from '@/views/admin/audit/PersonChange';
import UserChange from '@/views/admin/audit/UserChange';
export default {
  name: 'ChangeDetail',
  components: {UserChange, PersonChange, MemberChange, CardHistoryChange},
  props: {
    collectionId: {
      type: String,
      required: true
    },
    expand: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>

</style>
